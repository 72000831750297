// import node_modules
import * as PropTypes from 'prop-types';
import * as React from 'react';
import classNames from 'classnames';
import { RemixIcon } from '@storylinerlabs/design';

// import types
import { StorylinerIconAlignment, StorylinerIconAlignmentEnum } from '../../types';

// import styles
import styles from './StorylinerIcon.module.css';

// IconComponent
const IconComponent: React.FunctionComponent<{
  alignment?: StorylinerIconAlignment;
  icon: string;
}> = ({ alignment, icon }) => (
  <div className={classNames(styles['icon'], alignment && styles[alignment])}>
    <RemixIcon iconName={icon} />
  </div>
);

IconComponent.defaultProps = {
  alignment: 'left',
};

IconComponent.propTypes = {
  alignment: PropTypes.oneOf(Object.values(StorylinerIconAlignmentEnum)),
  icon: PropTypes.string.isRequired,
};

export default IconComponent;
